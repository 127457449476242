const initBlock = async function( block ) {
	const scrollBtn = block.find( ".js-hero-scroll" );

	scrollBtn.on( "click", function() {
		let scrollHeight = block.siblings( ".section" ).first().offset().top;
		let headerHeight = 0;
		let adminbarHeight = 0;

		if ( $( ".section-header" ).length ) {
			headerHeight = $( ".section-header" ).outerHeight();
		}

		if ( $( "#wpadminbar" ).length ) {
			adminbarHeight = $( "#wpadminbar" ).outerHeight();
		}

		$( "html, body" ).animate( {
			scrollTop: scrollHeight - headerHeight - adminbarHeight - 30
		}, 500 );
	} );
};

themeUtils.loadBlock( initBlock, "hero", ".section-hero" );
